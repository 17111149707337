<template>
  <!-- 设置弹窗 -->
  <el-dialog
    :title="list[opensetUp.type].title"
    :visible.sync="opensetUp.state"
    :before-close="handleClose"
    :close-on-click-modal="false"
    width="580px"
  >
    <div class="title">
      <i class="iconfont icon-folder" v-if="opensetUp.type == 0"></i>
      <p class="type_p">{{ opensetUp.name }}</p>
    </div>
    <p class="type_p">{{ list[opensetUp.type].edit }}</p>
    <el-input v-model="input" placeholder="请输入内容" class="input"></el-input>
    <p class="type_p">更改权限</p>
    <ul>
      <li @click="radio = 1">
        <div class="modal-radio-btn-wrapper _inline">
          <div class="modal-radio-btn bef _inline" v-if="radio == 1"></div>
        </div>
        <div style="float:left">
          <h5>{{ list[opensetUp.type].p1 }}</h5>
          <p>
            所有在 {{ opensetUp.name }} 中的成员都可查看编辑此{{
              list[opensetUp.type].p2
            }}
          </p>
        </div>
      </li>
      <li @click="radio = 2">
        <div class="modal-radio-btn-wrapper _inline">
          <div class="modal-radio-btn bef _inline" v-if="radio == 2"></div>
        </div>
        <div style="float:left">
          <h5>非公开</h5>
          <p>仅自己和被邀请的人可查看</p>
        </div>
      </li>
    </ul>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false" class="sub"
        >保存修改内容</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "setUp",
  data() {
    return {
      radio: 1,
      list: [
        {
          title: "项目",
          name: "项目名",
          edit: "修改项目名称",
          p1: "向团队公开",
          p2: "项目",
          p3: "项目名称"
        },
        {
          title: "设置",
          name: "paper名",
          edit: "修改paper名称",
          p1: "向项目公开",
          p2: "paper",
          p3: "paper名称"
        }
      ],
      input: "",
      dialogvisibel: true
    };
  },
  computed: {
    ...mapGetters(["opensetUp"])
  },
  mounted() {},
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-folder {
  color: #f5a623;
}
.title {
  i {
    margin-right: 8px;
    width: 20px;
    height: 22px;
  }
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.type_p {
  margin: 17px 0;
  font-size: 14px;
  display: inline-block;
}
.input {
  margin-bottom: 30px;
}
ul {
  li {
    box-shadow: 0 1px 0 0 #eee;
    padding: 20px 15px;
    background: #eee;
    cursor: pointer;
    display: flex;
    align-items: center;
    // justify-content: space-around;
    p {
    }
  }
}
.sub {
  background: #f5a623;
  border: #f5a623;
}

.modal-radio-btn-wrapper {
  float: left;
  cursor: pointer;
  margin-right: 15px;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  border: solid 1px #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .modal-radio-btn {
    box-shadow: 0 0 0 1.5px #f5a623;
    width: 13px;
    height: 13px;
    background: #f5a623;
    box-shadow: 0 0 0 1px #e6e8ed;
    border-radius: 50%;
  }
}
</style>
