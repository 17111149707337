<template>
  <div class="paper-dialog-compilation">
    <!-- 个人中心 -->
    <div class="user-ctrl-dialog" v-if="dialogElementShow.userCtrl">
      <span
        class="user-ctrl-open-setting iconfont icon-setting"
        data-type="1"
        @click="openUserSetting"
      ></span>
      <div class="user-ctrl-body display-flex">
        <div
          class="user-ctrl-photo xpp-user-photo"
          :style="xppUserInfo.headImg | styleUserPhoto"
        ></div>
        <div class="user-ctrl-userinfo">
          <div class="uc-userinfo-name text-overflow">
            {{ xppUserInfo.nickName || "佚名" }}
          </div>
          <div class="uc-userinfo-email text-overflow">
            {{ xppUserInfo.email || "无" }}
          </div>
          <div class="uc-userinfo-ascription">团队SVIP</div>
        </div>
      </div>
      <div class="user-ctrl-footer display-flex">
        <div class="user-ctrl-btn user-profile-switch" @click="userSwitch">
          切换账号
        </div>
        <div class="user-ctrl-btn user-profile-logout" @click="logout">
          退出登录
        </div>
      </div>
    </div>

    <!-- 个人信息设置 -->
    <div
      class="user-info-setting-dialog"
      v-if="dialogElementShow.userCtrlSettingOpen"
      data-parentend="1"
      @click="userSettingEvent"
    >
      <span
        class="user-info-setting-close el-icon-close"
        data-parentend="1"
      ></span>
      <el-tabs class="user-info-setting-body" type="border-card">
        <el-tab-pane label="简介">
          <div class="user-info-setting-item display-flex flex-start">
            <div
              class="user-info-setting-photo xpp-user-photo"
              @click="ctrlSelectUserPhoto"
              :style="xppUserInfo.headImg | styleUserPhoto"
            ></div>
            <div class="user-info-setting-portrait">
              <div
                class="user-info-setting-name text-overflow"
                @dblclick="editUserInfoNameText"
                title="双击修改"
              >
                <input
                  type="text"
                  v-model="xppUserInfo.nickName"
                  placeholder="请输入用户名"
                  maxlength="23"
                />
              </div>
              <div class="text-overflow">
                <b v-if="xppUserInfo.email">{{ xppUserInfo.email }}</b>
                <span
                  class="xpp-email-bind"
                  data-type="email"
                  v-else
                  @click="editUserInfo"
                  >绑定邮箱</span
                >
              </div>
              <div class="xpp-user-timeend">
                <b
                  >您的SVIP会员：{{
                    new Date(
                      new Date().getTime() + 86400000
                    ).toLocaleDateString()
                  }}到期</b
                >
              </div>
            </div>
            <a
              class="uc-userinfo-ascription"
              href="/pay/vippay"
              target="_blank"
              rel="noopener"
              >立即续费</a
            >
          </div>
          <div class="user-info-setting-item" data-title="公司名称">
            <div class="user-info-setting-text">
              <span>{{ xppUserInfo.companyName }}</span>
            </div>
          </div>
          <div class="user-info-setting-item" data-title="职位">
            <div class="user-info-setting-text">
              <span>{{
                xppUserInfo.position || "例如:设计师、开发人、策划人.."
              }}</span>
            </div>
            <span
              class="user-info-edit-btn"
              data-type="position"
              @click="editUserInfo"
              >编辑</span
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="设置">
          <div class="user-config-emailmsg display-flex">
            <b>邮件通知</b>
            <el-switch
              v-model="userConfig.emailMsg"
              active-color="#e49a22"
              inactive-color="#ccc"
            ></el-switch>
          </div>
        </el-tab-pane>
        <el-tab-pane label="容量">
          <div class="xpp-allowance-container">
            <div class="xpp-allowance-lable">
              <span>我的容量</span>
              <a href="javascript:;" rel="noopener">立即升级</a>
            </div>
            <div class="xpp-progress-box">
              <div class="xpp-progress">
                <div
                  :style="{ width: xppAllowanceInfo.storageSpacesUseRatio }"
                ></div>
              </div>
              <div class="xpp-allowance-info display-flex">
                <span
                  >{{ xppAllowanceInfo.storageSpaces | allowance }}M/{{
                    xppAllowanceInfo.originStorage | allowance
                  }}M</span
                >
                <span>已使用{{ xppAllowanceInfo.storageSpacesUseRatio }}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="流量"
          ><div class="xpp-allowance-container">
            <div class="xpp-allowance-lable">
              <span>我的流量</span>
              <a href="javascript:;" rel="noopener">立即升级</a>
            </div>
            <div class="xpp-progress-box">
              <div class="xpp-progress">
                <div
                  :style="{ width: xppAllowanceInfo.steamDataUseRatio }"
                ></div>
              </div>
              <div class="xpp-allowance-info display-flex">
                <span
                  >{{ xppAllowanceInfo.steamData | allowance }}M/{{
                    xppAllowanceInfo.originSteamData | allowance
                  }}M</span
                >
                <span>已使用{{ xppAllowanceInfo.steamDataUseRatio }}</span>
              </div>
            </div>
          </div></el-tab-pane
        >
      </el-tabs>
    </div>

    <!-- 余额不足提示 -->
    <div
      id="xppAllowanceCreditLow"
      class="display-flex flex-center"
      v-if="dialogElementShow.xppAllowanceCreditShow"
    >
      <div class="xpp-allowance-contianer">
        <el-button
          class="xpp-dialog-close"
          type="text"
          icon="el-icon-close"
          @click="offMask('xppAllowanceCreditShow', false, true)"
        ></el-button>
        <h3>{{ xppAllowanceCredit.tit }}</h3>
        <p>{{ xppAllowanceCredit.content }}</p>
        <a :href="xppAllowanceCredit.href + '?type=' + capacity" rel="noopener"
          >立即购买</a
        >
      </div>
    </div>

    <!-- 头像更换 -->
    <div
      id="userPhotoChangeDialog"
      v-if="dialogElementShow.userPhotoChangeDialog"
    >
      <div
        id="userPhotoChangeMaskClose"
        @click="userPhotoChangeMaskClose"
      ></div>
      <div
        id="userPhotoChangeContainer"
        @dragenter="fileDragenter"
        @dragleave="fileDragleave"
        @drop="fileDrop"
        :class="userPhotoDropActive"
      >
        <input
          id="userPhotoChangeInput"
          type="file"
          accept="image/*"
          @change="fileDrop"
        />
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__warn">图片大小不得超过3M</div>
      </div>
    </div>

    <!-- 消息通知 -->
    <div id="noticeContainer" v-if="dialogElementShow.noticeContainer">
      <el-tabs v-model="noticeTabsActive" @tab-click="noticeTabsHandleClick">
        <el-tab-pane label="通知" name="notice">
          <div class="noti-list-content" v-loading="notiloading">
            <button @click="notiAllRemRead">全部标记为已读</button>
            <div
              class="noti-scroll-container"
              data-type="noti"
              @scroll="notiScrollWatch"
            >
              <div v-for="(item, index) in noticeListInfo" :key="item.id">
                <div class="noti-scroll-time">
                  <el-tooltip
                    :content="item.time | dateToLocale"
                    placement="top"
                  >
                    <span>{{ item.time | toDateString }}</span>
                  </el-tooltip>
                </div>
                <ul @click="notiNavigator">
                  <li
                    class="display-flex flex-start"
                    v-for="(li, idx) in item.list"
                    :class="{ 'noti-unread': !li.readFlag }"
                    :data-keyone="index"
                    :data-keytwo="idx"
                    :data-type="li.remindType"
                    :key="li.id"
                  >
                    <div
                      class="noti-user-photo xpp-user-photo"
                      :style="li.reminduserHeadimg | styleUserPhoto"
                    ></div>
                    <div class="noti-con-info">
                      <p>
                        <b>{{ li.reminduserName || "佚名" }}</b
                        >在<b>{{ li.projectName }}</b
                        >项目的<b>{{ li.paperName }}</b
                        ><u>Paper</u>里，{{ li.remindType | remindTypeFIlter }}
                      </p>
                      <span class="noti-position-time">{{
                        li.remindDate | toDateString
                      }}</span>
                      <div class="project-comp-ui">
                        <i class="iconfont icon-folder"></i>
                        <span>{{ li.projectName }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- 显示此节点时需要将.el-tabs__item 宽度重新设置  当前为100% -->
        <!-- <el-tab-pane label="活动" name="track">
          <p>暂无活动信息</p>
        </el-tab-pane> -->
      </el-tabs>
    </div>

    <!--  paper_page 标题修改  -->
    <el-dialog
      :visible.sync="dialogElementShow.paperPageTitEdit"
      :close-on-click-modal="false"
      title="设置页标题"
      width="440px"
    >
      <el-row>
        <label class="paper-page-title-edit-label">
          <input
            class="paper-page-title-edit-form"
            type="text"
            ref="paperTitleEditInput"
            v-model="paperPageEditDate.title"
            placeholder="请输入页面标题"
            @compositionstart="compositionstart"
            @compositionend="compositionend"
            @keydown.enter="sendTitEditInfo"
          />
        </label>
      </el-row>
      <el-row class="paper-page-title-edit-footer text-right">
        <el-button plain @click="closeTitEdit">取消</el-button>
        <el-button type="warning" @click="sendTitEditInfo">保存</el-button>
      </el-row>
    </el-dialog>

    <!-- page 背景色修改 -->
    <div
      class="xpp-page-bg-edit"
      v-show="dialogElementShow.pageBgEdit"
      :style="pageMenuViewPosition"
      ref="xppPageBgEdit"
    >
      <div class="xpp-page-bg-edit-tit">背景颜色</div>
      <div class="xpp-page-bg-edit-color">
        <div
          class="xpp-page-bg-menu"
          v-for="(color, index) in pageBgMenuList"
          :key="index"
          @click="savePageBgChange(color)"
        >
          <span :style="{ backgroundColor: color }"></span>
        </div>
        <div class="xpp-page-bg-menu">
          <el-color-picker
            class="xpp-color-picker"
            color-format="hex"
            v-model="pageBgColor"
            :predefine="predefineColors"
            @change="colorPickerChange"
          ></el-color-picker>
        </div>
      </div>
    </div>

    <!--  page功能菜单  -->
    <div
      class="xpp-context-menu"
      v-if="dialogElementShow.pageMoreMenu"
      :style="pageMenuViewPosition"
    >
      <ul @click="openPageMoreMenu">
        <li
          class="xpp-context-menu-item"
          v-for="menu in paperMoreMenu"
          :key="menu.label"
          :data-type="menu.label"
        >
          <i class="iconfont" :class="menu.icon"></i
          ><span>{{ menu.text }}</span>
        </li>
      </ul>
    </div>

    <!--  调整页面大小  -->
    <el-dialog
      :visible.sync="dialogElementShow.pageSizeEditView"
      :close-on-click-modal="false"
      title="设置页面尺寸"
      width="380px"
    >
      <el-row>
        <p style="padding-bottom: 10px;">页面大小应介于 500 和 35000 之间。</p>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form :model="pageSizeEditForm">
            <el-form-item label="宽度" label-position="top">
              <el-input-number
                placeholder="输入值"
                v-model="pageSizeEditForm.width"
                controls-position="right"
                size="small"
                :min="500"
                :max="35000"
              >
              </el-input-number>
            </el-form-item>
            <el-form-item label="高度" label-position="top">
              <el-input-number
                placeholder="输入值"
                v-model="pageSizeEditForm.height"
                controls-position="right"
                size="small"
                :min="500"
                :max="35000"
              >
              </el-input-number>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="2">
          <div class="page-size-preview">
            <div :style="pageSizePreview"></div>
          </div>
          <div class="text-right">
            <span
              >{{ pageSizeEditForm.width }} x
              {{ pageSizeEditForm.height }}</span
            >
          </div>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button plain @click="offMask('pageSizeEditView', false)"
          >取消</el-button
        >
        <el-button type="warning" @click="startPageSizeEdit">确定</el-button>
      </div>
    </el-dialog>

    <!-- 分享paper -->
    <el-dialog
      :visible.sync="xppPaperShareDialogInfo.state"
      :close-on-click-modal="false"
      title="分享给其他人"
      width="640px"
      custom-class="xpp-share-dialog"
    >
      <h2 slot="title">分享给其他人</h2>
      <h3 class="xpp-share-mode-title">{{ xppPaperShareDialogInfo.name }}</h3>
      <div class="xpp-share-mode-container" data-label="输入需要邀请的邮箱">
        <div class="display-flex">
          <div class="xpp-share-form-con">
            <div class="xpp-share-usersearch">
              <UserSearchInput
                v-model="activeMembersInfo"
                :searchtype="2"
                radio="1"
              />
              <!-- :stranger="true" -->
            </div>
            <el-select
              class="xpp-share-select"
              popper-class="xpp-share-select-popper"
              v-model="xppShareModel1"
            >
              <el-option
                v-for="item in xppShareOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <el-button type="warning" @click="xppShareEmail">分享</el-button>
        </div>
      </div>
      <div class="xpp-share-mode-container" data-label="通过链接分享">
        <div class="display-flex">
          <div class="xpp-share-form-con display-flex">
            <input
              ref="xppShareLInkInput"
              class="xpp-share-input"
              type="text"
              readonly
              @focus="xppShareLinkCopy"
              v-loading="xppPaperShareDialogInfo.loading"
              v-model="xppPaperShareDialogInfo.share"
            />
            <el-select
              class="xpp-share-select"
              popper-class="xpp-share-select-popper"
              v-model="xppShareModel2"
              @change="xppShareModeChange"
            >
              <el-option
                v-for="item in xppShareOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <el-button type="warning" @click="xppShareLinkCopy">复制</el-button>
        </div>
      </div>
      <div class="xpp-share-mode-container" data-label="高级设置">
        <div><span class="xpp-sharemode-btn">重置分享链接</span></div>
        <div v-if="xppProjectMemberInfo.observerAuthority">
          <span class="xpp-sharemode-btn" @click="openprotectPaper()"
            >为paper设置密码</span
          >
        </div>
        <div v-if="xppProjectMemberInfo.observerAuthority">
          <span class="xpp-sharemode-btn" @click="openMemberDialog"
            >管理合作者</span
          >
        </div>
      </div>
    </el-dialog>

    <!-- 设置弹窗 -->
    <setUp :dialogVisible="opensetUp.state" />

    <!-- 退出项目弹窗 -->
    <el-dialog title="提示" :visible.sync="outProject.show" width="30%">
      <p v-if="outProject.type == 1">
        <i class="iconfont icon-folder" style="color:#f7bc03"></i
        >{{ outProject.project.name }} <br />
        确定要退出此项目？
      </p>
      <span v-if="outProject.type == 2"
        >确定将{{ outProject.project.name }}移动到回收站？</span
      >
      <span v-if="outProject.type == 3"
        >删除项目后，项目内的所有数据将被永久删除。您确定要删除吗？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="outProjectEvent"
          style="background:red;color:#fff;border:none;"
          >确认</el-button
        >
      </span>
    </el-dialog>

    <!-- :visible.sync="joinTheTeamDialog.state" -->
    <el-dialog
      custom-class="join-team-dialoh"
      :visible.sync="joinTheTeamDialog.state"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="410px"
    >
      <h4 slot="title" class="join-team-title">团队邀请</h4>
      <div class="join-team-container">
        <h3>{{ joinTheTeamDialog.info.companyName }}</h3>
        <div
          class="xpp-user-photo"
          :style="joinTheTeamDialog.info.reminduser_headimg | styleUserPhoto"
        ></div>
        <div class="join-team-info">
          <b>{{ joinTheTeamDialog.info.reminduser_name }}</b>
          <i>（{{ joinTheTeamDialog.info.reminduser_email }}）</i>
          <span>邀请你加入</span>
          <b>"{{ joinTheTeamDialog.info.companyName }}"</b>
          <span>团队</span>
        </div>
        <div class="join-team-option display-flex">
          <el-button @click="joinTeamSubmit(1)">接受</el-button>
          <el-button @click="joinTeamSubmit(0)">拒绝</el-button>
        </div>
        <!-- <p class="join-team-remarks">
          此信息已发送至：<span>{{ joinTheTeamDialog.info.remindemail }}</span>
        </p> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { aliyunPut } from "@plugins/aliyunOss";
import setUp from "@components/setUp";
import contextMenu from "@plugins/contextMenu";
import UserSearchInput from "@components/UserSearchInput";
import { formParamsFormat } from "@plugins/toolWheel";
export default {
  name: "Dialog",
  mixins: [contextMenu],
  components: {
    setUp,
    UserSearchInput
  },
  computed: {
    userPhotoDropActive() {
      return {
        "drop-active": this.dropActiveState
      };
    },
    pageMenuViewPosition() {
      let maxH = document.documentElement.clientHeight;
      let menu = this.dialogElementShow.pageBgEdit;
      let { width, left, bottom } = this.pagePositionInfo;
      if (bottom + 178 > maxH) {
        bottom -= bottom + 178 - maxH;
      }
      return {
        top: Math.floor(bottom) + "px",
        left: Math.floor(left - (menu ? 300 : 242) + width + 10) + "px"
      };
    },
    xppCommentOffset() {
      let style = {};
      let { top, left, width, height } = this.elementCommentInfo.offset;
      style.left = Math.floor(left + width + 5) + "px";
      style.top = top + height - 80 + "px";
      this.$nextTick(() => {
        this.upCommentsBoxOffset();
      });
      return style;
    },
    pageSizePreview() {
      let { width, height } = this.pageSizeEditForm;
      let ratio = 1;
      let widthSize = 0;
      let heightSize = 0;
      if (width > height) {
        ratio = height / width;
        widthSize = 140;
        heightSize = widthSize * ratio;
      } else {
        ratio = width / height;
        heightSize = 140;
        widthSize = heightSize * ratio;
      }
      return {
        width: widthSize + "px",
        height: heightSize + "px"
      };
    },
    paperMoreMenu() {
      /* {
        icon: "icon-copy",
        label: "copy_page",
        text: "复制页面"
      } */
      let arr = [
        {
          icon: "icon-new_page",
          label: "new_page",
          text: "添加新页面"
        },
        {
          icon: "icon-page-size-change-icon",
          label: "size_page",
          text: "更改页面大小"
        },
        {
          icon: "icon-duplicate_icon",
          label: "duplicate_page",
          text: "创建页面副本"
        },
        {
          icon: "icon-delete",
          label: "del_page",
          text: "删除页面"
        }
      ];
      if (this.paperViewAll.length === 1) {
        arr.pop();
      }
      return arr;
    },
    elementCommentList() {
      return this.elementCommentInfo.list;
    },
    ...mapGetters([
      "xppUserInfo",
      "paperGroupId",
      "paperPageEditDate",
      "dialogElementShow",
      "pagePositionInfo",
      "paperViewAll",
      "paperViewZoomInfo",
      "elementCommentInfo",
      "noticeListInfo",
      "noticeListPageInfo",
      "homeViewScale",
      "xppAllowanceCredit",
      "xppAllowanceInfo",
      "xppPaperShareDialogInfo",
      "xppProjectMemberInfo",
      "opensetUp",
      "outProject",
      "joinTheTeamDialog",
      "xcanvasReadyState"
    ])
  },
  filters: {
    allowance(num) {
      return Math.floor((num / 1024 / 1024) * 100) / 100;
    },
    remindTypeFIlter(type) {
      let str = "";
      switch (type + "") {
        case "1": // 在评论中被@
          str = "添加了一条提到你的评论";
          break;
        case "2": // 在节点中被@
          str = "提到了你";
          break;
        case "4": // 评论被回复
          str = "你创建的评论上添加了评论";
          break;
        case "5": // 创建的节点被添加评论
          str = "评论了你创建的节点";
          break;
      }
      return str;
    }
  },
  watch: {
    "dialogElementShow.pageSizeEditView"(val) {
      if (val) {
        let { width, height } = this.paperViewZoomInfo;
        this.pageSizeEditForm.width = width;
        this.pageSizeEditForm.height = height;
      }
    },
    "dialogElementShow.paperPageTitEdit"(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.paperTitleEditInput.focus();
        });
      }
    }
  },
  data() {
    return {
      userConfig: {
        emailMsg: true
      },
      resourceType: "",
      value: [],
      notiloading: false, //通知菜单加载状态
      dropActiveState: false, // 更换图片拖拽
      userPhotoSize: 1024 * 1024 * 3,
      paperPageTit: "",
      pageSizeEditForm: {
        width: 0,
        height: 0
      },
      pageBgMenuList: [
        "#FFFFFF",
        "#FAFAFA",
        "#E4E4E4",
        "#CCCCCC",
        "#B1B1B1",
        "#838383",
        "#6F6F6F",
        "#515151",
        "#343434",
        "#000000",
        "#EFE5C4",
        "#E5EFC4",
        "#CEEFC4",
        "#C8E3D7",
        "#C6E1E0",
        "#97A1AE",
        "#A8BCD8",
        "#C0BDF1",
        "#B398C3",
        "#E9D2EA",
        "#E7C555",
        "#E5EFC4",
        "#A8CF8D",
        "#91CEB3",
        "#90D4D1",
        "#97A1AE",
        "#8BA6C1",
        "#8D85ED",
        "#CB76B2"
      ],
      // 重新编辑的评论信息
      repeatEditCommentInfo: {
        active: false,
        id: "",
        content: ""
      },
      // 选择分享时查看权限
      xppShareOptions: [
        {
          label: "可编辑",
          value: "2"
        },
        {
          label: "可评论",
          value: "3"
        },
        {
          label: "可查看",
          value: "1"
        }
      ],
      xppShareModel1: "2",
      xppShareModel2: "2",
      noticeTabsActive: "notice", // 通知菜单
      activeMembersInfo: {},
      pageBgColor: "", // 页面背景色
      predefineColors:
        JSON.parse(localStorage.getItem("pagePredefineColors")) || [] // 页面背景色预选
    };
  },
  methods: {
    async outProjectEvent() {
      let { type } = this.outProject;
      //退出项目
      switch (type) {
        case 1:
          let obj = {
            companyId: this.outProject.project.companyId,
            resourceId: this.outProject.project.id
          };
          await this.$axios.post("/per/m/out", formParamsFormat(obj));
          this.$setGlobalUploadStatys();
          break;
        case 2:
          if (this.outProject.project.projectId) {
            this.resourceType = 2;
            await this.$axios.post("/dis/recycle/add", {
              resourceType: this.resourceType,
              id: this.outProject.project.id,
              delFlag: 1
            });
            this.$setGlobalUploadStatys();
          } else {
            this.resourceType = 1;
            await this.$axios.post("/dis/recycle/add", {
              resourceType: this.resourceType,
              id: this.outProject.project.id,
              delFlag: 1
            });
            this.$setGlobalUploadStatys();
          }
          break;
        case 3:
          await this.$axios.post("/dis/recycle/delect", {
            resourceType: this.outProject.project.soruceType,
            id: this.outProject.project.id
          });
          this.$setGlobalUploadStatys();
          break;
      }
      //这个是选中的对象
      // let obj = this.outProject.data;
      this.$outProject({ show: false });
    },
    //
    passblur(value) {
      let array = [...document.getElementsByClassName(value)];
      array.forEach((item, index) => {
        item.oninput = () => {
          if (item.value != "" && index < array.length - 1) {
            array[index + 1].focus();
          }
        };
      });
    },
    // 打开设置密码弹窗
    openprotectPaper() {
      this.$UpdataVuexState({
        key: "xppPaperPassword",
        data: { show: true, state: 2, founderInfo: this.xppUserInfo }
      });
    },
    offMask(key, value, exclusive) {
      this.$DiaLoginFoUpDate({
        key,
        value,
        exclusive
      });
      this.$UpdataVuexState({ key: "viewMaskShow", data: value });
    },
    openUserSetting(event) {
      this.offMask("userCtrl", false);
      this.offMask("userCtrlSettingOpen", !!event.target.dataset.type);
    },
    userSettingEvent(event) {
      let { target } = event;
      if (target.dataset.parentend) {
        event.stopPropagation();
        return this.offMask("userCtrlSettingOpen", false);
      }
    },
    async updataUserInfo(email) {
      let {
        companyId,
        headImg,
        nickName,
        position,
        companyName
      } = this.xppUserInfo;
      await this.$axios.post("/dis/u/update", {
        companyId,
        headImg,
        email,
        nickName,
        position,
        companyName
      });
      this.$message({
        type: "success",
        message: "您的信息更改已提交"
      });
      this.$SaveXppUserInfo(this.xppUserInfo);
    },
    // 修改用户信息中某字段
    editUserInfo({ target }) {
      switch (target.dataset.type) {
        case "companyName":
          this.$prompt("更改公司名").then(({ value }) => {
            this.xppUserInfo.companyName = value;
            this.updataUserInfo();
          });
          break;
        case "position":
          this.$prompt("编辑职位").then(({ value }) => {
            this.xppUserInfo.position = value;
            this.updataUserInfo();
          });
          break;
        case "email":
          this.$prompt(
            "您目前没有绑定邮箱地址，为了更好体验Xpaper的完整功能，请输入邮箱并绑定！",
            "邮箱绑定",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
              inputErrorMessage: "邮箱格式不正确"
            }
          ).then(({ value }) => {
            this.xppUserInfo.email = value;
            this.updataUserInfo(value);
          });
          break;
      }
    },
    editUserInfoNameText({ target }) {
      let input = target.childNodes[0];
      input.style.pointerEvents = "auto";
      target.classList.add("element-text-focus");
      input.focus();
      input.onblur = () => {
        input.style.pointerEvents = null;
        target.classList.remove("element-text-focus");
        if (this.xppUserInfo.nickName.trim() !== "") {
          this.updataUserInfo();
        }
      };
    },
    ctrlSelectUserPhoto() {
      this.$DiaLoginFoUpDate({
        key: "userPhotoChangeDialog",
        value: true
      });
    },
    async logout() {
      this.$Logout();
      await this.$axios.post("/dis/auth/logout");
      this.offMask("userCtrl", false);
      this.$OnlineStateChange(false);
      sessionStorage.setItem(
        "returnUrl",
        location.href.replace(location.origin, "")
      );

      location.href = "/";
    },
    userSwitch() {
      this.$router.push({
        path: "/passport/login"
      });
      this.offMask("userCtrl", false);
    },
    closeTitEdit() {
      this.offMask("paperPageTitEdit", false);
    },
    sendMsgPack(type, obj) {
      let idx = document.getElementById(obj.id).dataset.index;
      let msgData = {
        action: "UPDATEPAPER",
        data: {
          _id: this.paperPageEditDate.id,
          type,
          index: idx
        }
      };
      msgData.data[type] = obj[type];
      this.$WsSendMsg(msgData);
      this.$UpDatePaperPage(msgData.data);
    },
    compositionstart({ target }) {
      target.dataset.edit = true;
    },
    compositionend({ target }) {
      target.dataset.edit = false;
    },
    async sendTitEditInfo({ target }) {
      let edit = target.dataset.edit;
      console.log(edit);
      if (target.tagName === "INPUT" && edit === "true") {
        return;
      }
      let params = new FormData();
      params.append("paperId", this.paperPageEditDate.id);
      params.append("paperName", this.paperPageEditDate.title);
      await this.$axios.post("/dis/p/update", params);
      this.sendMsgPack("pageTitle", {
        id: this.paperPageEditDate.id,
        pageTitle: this.paperPageEditDate.title
      });
      this.closeTitEdit();
    },
    savePageBgChange(backgroundColor) {
      this.sendMsgPack("pageStyle", {
        id: this.paperPageEditDate.id,
        pageStyle: {
          backgroundColor
        }
      });
      this.offMask("pageBgEdit", false);
    },
    colorPickerChange(val) {
      this.predefineColors.unshift(val);
      if (this.predefineColors.length > 20) {
        this.predefineColors = this.predefineColors.splice(0, 20);
      }
      localStorage.setItem(
        "pagePredefineColors",
        JSON.stringify(this.predefineColors)
      );
      this.savePageBgChange(val);
    },
    adjustNodePosition(pw, ph) {
      function px(t) {
        return t.replace("px", "") - 0;
      }
      let all = [];
      this.paperViewAll.map(item => {
        let keys = Object.keys(item).filter(
          item => item.indexOf("Sequence") > 0
        );
        for (let i in keys) {
          let sequence = item[keys[i]];
          let sKeys = Object.keys(sequence);
          if (!sKeys.length) continue;
          let tt = [];
          for (let o in sKeys) {
            let tg = sequence[sKeys[o]];
            let { style } = tg;
            let top = px(style.top);
            let left = px(style.left);
            let width = px(style.width);
            let height = px(style.height);
            let tOff = top + height > ph;
            let lOff = left + width > pw;
            if (tOff) {
              style.top = ph - height + "px";
            }
            if (lOff) {
              style.left = pw - width + "px";
            }
            if (tOff || lOff) {
              tt.push(tg);
            }
          }
          if (tt.length) {
            all.push(tt);
          }
        }
        return item;
      });
      if (!all.length) return this.sendPageSizeEdit(pw, ph);
      this.setLoadingState(true);
      let dd = tt => {
        if (!tt) return this.sendPageSizeEdit(pw, ph);
        this.updataPaperInfo(tt);
        setTimeout(() => {
          dd(all.shift());
        }, 150);
      };
      dd(all.shift());
    },
    setLoadingState(data) {
      this.$WsSendMsg({
        action: "SETLOADING",
        data
      });
      this.$PaperLoadingState(data);
    },
    startPageSizeEdit() {
      this.offMask("pageSizeEditView", false);
      let { width, height } = this.pageSizeEditForm;
      this.adjustNodePosition(width, height);
    },
    async sendPageSizeEdit(width, height) {
      let msgData = {
        action: "UPDATEPAPER",
        data: {
          _id: this.paperGroupId,
          type: "pageSize",
          pageSize: {
            width,
            height
          }
        }
      };
      this.$WsSendMsg(msgData);
      this.$UpDatePaperPage(msgData.data);
      if (this.xcanvasReadyState) return;
      this.setLoadingState(false);
    },
    openPageMoreMenu({ target }) {
      let type = target.dataset.type;
      switch (type) {
        case "new_page":
          this.createPaperPage(true);
          break;
        case "size_page":
          this.offMask("pageSizeEditView", true, true);
          break;
        case "del_page":
          this.delPaperPage();
          break;
        default:
          this.$message("暂时点不了哦");
          break;
      }
      this.offMask("pageMoreMenu");
    },
    // 通知窗口 切换tabs
    noticeTabsHandleClick(tabs) {
      switch (tabs.name) {
        case "notice":
          this.noticeListPage = 1;
          this.$UpdataNoticeListInfo();
          break;
        case "track":
          this.$axios.get("/mo/active/list", {
            params: {
              companyId: this.xppUserInfo.companyId,
              page: 1
            }
          });
          break;
      }
    },
    // 跳转到通知信息目标
    async notiNavigator({ target }) {
      this.offMask("noticeContainer", false);
      let data = this.noticeListInfo[target.dataset.keyone].list[
        target.dataset.keytwo
      ];
      if (!data.readFlag) {
        data.readFlag = 1;
        await this.notiRemRead({
          userId: this.xppUserInfo.id,
          id: data.id,
          readFlag: "1"
        });
        this.$SaveNoticeListPageInfo();
      }
      switch (target.dataset.type) {
        case "1":
        case "4":
        case "5":
          data.elementType = "comment";
          break;
      }
      localStorage.setItem("notiNavigatorInfo", JSON.stringify(data));
      let route = this.$route;
      if (route.name === "Paper" && data.paperId === route.params.id) {
        this.$NavToTargetElement();
      } else {
        window.open(`/paper/${data.paperId}`, "_blank", "noopener");
        // location.href = location.origin + `/paper/${data.paperId}`;
      }
    },
    async notiAllRemRead() {
      for (let i in this.noticeListInfo) {
        for (let o in this.noticeListInfo[i].list) {
          this.noticeListInfo[i].list[o].readFlag = 1;
        }
      }
      await this.notiRemRead({
        userId: this.xppUserInfo.id,
        readFlag: "1"
      });
      this.$UpdataVuexState({
        key: "noticeListPageInfo",
        data: { unReadCount: 0 }
      });
    },
    async notiRemRead(data) {
      let params = new FormData();
      let keys = Object.keys(data);
      for (let i of keys) {
        params.append(i, data[i]);
      }
      await this.$axios.post("/dis/rem/read", params);
    },
    // 通知列表/活动列表 滚动监听
    async notiScrollWatch(event) {
      let { target } = event;
      switch (target.dataset.type) {
        case "noti":
          let { total, currentTotal, pageNum } = this.noticeListPageInfo;
          console.log(total, currentTotal, pageNum);
          if (
            target.scrollHeight - target.scrollTop <=
              target.clientHeight + 100 &&
            total > currentTotal
          ) {
            this.notiloading = true;
            target.dataset.type = null;
            await this.$UpdataNoticeListInfo({
              pageNum: pageNum + 1
            });
            this.notiloading = false;
            target.dataset.type = "noti";
          }
          break;
      }
    },
    // 关闭图片上传窗口
    userPhotoChangeMaskClose() {
      this.$DiaLoginFoUpDate({
        key: "userPhotoChangeDialog",
        value: false
      });
    },
    fileDragenter(event) {
      event.preventDefault();
      this.dropActiveState = true;
    },
    fileDragleave() {
      event.preventDefault();
      this.dropActiveState = false;
    },
    async fileDrop(event) {
      event.preventDefault();
      event.stopPropagation();
      let files;
      switch (event.type) {
        case "change":
          files = event.target.files;
          break;
        case "drop":
          files = event.dataTransfer.files;
          break;
      }
      if (files.length !== 1) {
        return this.$message.error("请选择单个图片文件");
      }
      let file = files[0];
      if (file.type.indexOf("image") < 0) {
        return this.$message.error("请选择图片类文件");
      }
      if (file.size > this.userPhotoSize) {
        return this.$message.error("图片大小不得超过3M");
      }
      let upload = await aliyunPut(file);
      if (upload && upload.name) {
        this.xppUserInfo.headImg = `https://5gcoolwork.oss-accelerate.aliyuncs.com/${upload.name}`;
        await this.updataUserInfo();
        this.userPhotoChangeMaskClose();
      } else {
        this.$message.error("上传失败，请重试");
        console.warn(upload);
      }
    },
    // 变更分享权限
    xppShareModeChange() {
      this.$UpdataXppPaperShareDialogInfo({
        paperId: this.xppPaperShareDialogInfo.id,
        operateMode: this.xppShareModel2
      });
    },
    // 复制分享链接
    xppShareLinkCopy() {
      this.$refs.xppShareLInkInput.select();
      document.execCommand("Copy");
      this.$message({
        message: "链接已复制到剪贴板",
        type: "success",
        duration: 1500
      });
    },
    // 邮箱分享
    async xppShareEmail() {
      let email = Object.keys(this.activeMembersInfo);
      if (!email.length) {
        return this.$message({
          message: "未选择被邀请邮箱",
          type: "warning",
          duration: 1000
        });
      }
      let info = this.activeMembersInfo[email[0]];
      await this.$axios.post("/dis/p/emailshare", {
        email: info.email,
        operateMode: this.xppShareModel1 - 0,
        paperId: this.paperGroupId,
        expire: 6000
      });
      this.$message("邀请邮件已发送");
      this.$set(this, "activeMembersInfo", {});
      this.$set(this.xppPaperShareDialogInfo, "state", false);
    },
    // 打开成员管理面板
    openMemberDialog() {
      this.$UpdataVuexState({
        key: "xppProjectMemberInfo",
        data: {
          status: true,
          title: document.title,
          projetcId: this.paperGroupId
        }
      });
    },
    async joinTeamSubmit(status) {
      await this.$axios.post("/dis/team/invitationOperation", {
        team_id: this.joinTheTeamDialog.info.companyId,
        status
      });
      if (status === 1) {
        this.xppUserInfo.companyId = this.joinTheTeamDialog.info.companyId;
        this.$SaveXppUserInfo(this.xppUserInfo);
      }
      this.joinTeamClose();
    },
    async joinTeamClose() {
      this.$UpdataVuexState({
        key: "joinTheTeamDialog",
        data: { state: false }
      });
    },
    ...mapMutations([
      "$UpdataVuexState",
      "$DiaLoginFoUpDate",
      "$Logout",
      "$UpDatePaperPage",
      "$SplicePaperPage",
      "$SaveXppUserInfo",
      "$SaveNoticeListPageInfo",
      "$outProject",
      "$setRefresh",
      "$setGlobalUploadStatys",
      "$PaperLoadingState"
    ]),
    ...mapActions([
      "$WsSendMsg",
      "$OnlineStateChange",
      "$NavToTargetElement",
      "$UpdataNoticeListInfo",
      "$UpdataXppPaperShareDialogInfo"
    ])
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.inputpass {
  width: 70%;
  display: table;
  table-layout: fixed;
  margin: 27px auto 30px;
  display: flex;
  justify-content: space-between;
  input {
    border: solid 1px #dbdee6;
    width: 40px;
    height: 40px;
    line-height: 1;
    font-weight: 500;
    padding: 0;
    text-align: center;
    min-width: 40px;
    max-width: 40px;
    border-radius: 4px;
    display: block;
    font-size: 18px;
    margin: 0;
  }
}
.subpass {
  background-color: #f5a623;
  display: block;
  margin: 0 auto;
  padding: 0 15px;
  width: auto;
  color: #fff;
  height: 40px;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  border: none;
  border-radius: 5px;
}
.showpass {
  position: absolute;
  right: 0;
  background: #fff;
  bottom: -70px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 50px;
  & p:nth-child(1) {
    margin: 16px 0 18px 19px;
    width: auto;
    max-width: 113px;
    color: #9197a3;
    line-height: 1.9;
  }
  div {
    margin: 15px 0 18px 11px;
    h5 {
      font-size: 14px;
      font-weight: normal;
    }
    span {
      font-size: 12px;
      color: #9197a3;
    }
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #409eff;
    margin: 15px 0 18px 11px;
  }
}
.user-ctrl-dialog {
  position: fixed;
  z-index: $view-mask-modal;
  top: $header-height-default;
  right: 12px;
  background-color: #ffffff;
  border: 1px solid #dbdee6;
  box-shadow: 0 9px 40px 0 rgba(28, 30, 31, 0.14);
  padding: 4px 0;
  .user-ctrl-open-setting {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    color: #858c93;
    cursor: pointer;
    border-radius: 100%;
    &:hover {
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);
      background-color: #d3d3d3;
    }
  }
}

.user-ctrl-body {
  padding: 12px 20px;
  width: 330px;
  box-sizing: border-box;
  justify-content: flex-start;
  border-bottom: 1px solid #f2f2f2;
  .user-ctrl-photo {
    flex: none;
    width: 98px;
    height: 98px;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    margin-right: 12px;
  }
  .user-ctrl-userinfo {
    flex: none;
    width: 160px;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
  }
  .uc-userinfo-name {
    color: #373839;
  }
  .uc-userinfo-email {
    color: #8e959a;
  }
  .uc-userinfo-ascription {
    display: inline-block;
    background: #c9d0d5;
    color: #fff;
    line-height: 24px;
    padding: 0 10px;
    margin-top: 8px;
    border-radius: 100px;
    font-size: 12px;
  }
}

.user-ctrl-footer {
  font-size: 14px;

  .user-ctrl-btn {
    flex: none;
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
  }

  .user-profile-switch {
    color: #f5a623;
  }

  .user-profile-logout {
    color: #8e959a;
  }
}

.user-info-setting-dialog {
  position: fixed;
  z-index: $view-mask-modal;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(55, 55, 55, 0.8);
  .user-info-setting-close {
    position: absolute;
    z-index: 10;
    width: 80px;
    height: 80px;
    top: 40px;
    right: 40px;
    color: #fff;
    font-size: 40px;
    line-height: 80px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 100%;
    transform: scale(1);
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.3s, transform 0.3s;
    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }
  }
  .user-info-setting-body {
    flex: none;
    margin-top: 70px;
    width: 600px;
  }
  .user-info-setting-item {
    border-bottom: 1px solid #dbdee6;
    padding: 15px 30px;
    &::before {
      content: attr(data-title);
      display: block;
      font-size: 17px;
      font-weight: 700;
      margin-bottom: 8px;
      color: #333;
    }
    &:first-child::before {
      content: none;
    }
  }
  .user-info-setting-photo {
    position: relative;
    flex: none;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    width: 83px;
    height: 83px;
    border-radius: 100%;
    overflow: hidden;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  .user-info-setting-portrait {
    margin-left: 18px;
    .text-overflow {
      max-width: 100%;
    }
    div {
      &:nth-child(2) {
        font-size: 12px;
        color: #1f1f1f;
        line-height: 16px;
      }
    }

    .user-info-setting-name {
      width: 360px;
      margin-bottom: 2px;
      &:hover {
        color: #f5a623;
      }
      input {
        width: 100%;
        height: 42px;
        font-size: 30px;
        font-weight: 700;
        line-height: 42px;
        color: inherit;
        padding: 0;
        pointer-events: none;
      }
    }
    .xpp-user-timeend {
      font-size: 12px;
      opacity: 0.8;
      margin-top: 7px;
    }
  }
  .user-info-setting-text {
    font-size: 14px;
    color: #9197a3;
  }
  .uc-userinfo-ascription {
    flex: none;
    height: 23px;
    font-size: xx-small;
    margin-left: -39px;
    line-height: 23px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 23px;
    background: linear-gradient(
      135deg,
      rgba(246, 188, 2, 1) 0%,
      rgba(247, 107, 28, 1) 100%
    );
    color: #fff;
    text-align: center;
    align-self: flex-end;
    cursor: pointer;
  }
  .user-config-emailmsg {
    font-size: 14px;
  }
}

.user-info-setting-item {
  position: relative;
  &:hover {
    .user-info-edit-btn {
      opacity: 1;
    }
  }
  &:first-child {
    border-bottom: none;
  }
  .user-info-edit-btn {
    position: absolute;
    right: 30px;
    top: 38px;
    font-size: 14px;
    color: #f5a623;
    opacity: 0;
    cursor: pointer;
  }
}

.paper-page-title-edit-label {
  display: flex;
  input {
    width: 100%;
  }
}

.paper-page-title-edit-form {
  border: 1px solid #dbdee6;
  line-height: 1.5;
  font-weight: 500;
  padding: 0.55em 1em;
  font-size: 14px;
  box-shadow: 0 0 0 1px transparent;
  border-collapse: collapse;
  border-radius: 4px;
  &:focus {
    border-color: #f5a623;
  }
}

.paper-page-title-edit-footer {
  margin-top: 30px;
}

.xpp-page-bg-edit {
  position: fixed;
  z-index: $view-mask-modal;
  width: 260px;
  height: auto;
  background-color: #fff;
  padding: 10px 14px;
  border-radius: 12px;
  border: 1px solid #dbdee6;
  box-shadow: 0 3px 33px 0 rgba(0, 0, 0, 0.15);

  .xpp-page-bg-edit-tit {
    font-size: 14px;
    color: #aaa;
    margin-bottom: 0.5em;
  }

  .xpp-page-bg-edit-color {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .xpp-page-bg-menu {
    flex: none;
    width: 26px;
    height: 30px;
    vertical-align: top;
    text-align: center;
    cursor: pointer;

    > span {
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 1px solid #eaeaea;
      border-radius: 100%;
    }
  }
}

.el-input-number {
  max-width: 100%;
}

.page-size-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 8px;
  width: 140px;
  height: 140px;

  div {
    border: 1px solid #dbdee6;
    border-radius: 4px;
    background-color: #fff;
  }
}

#noticeContainer {
  position: fixed;
  top: 50px;
  right: 70px;
  height: auto;
  overflow: hidden;
  background-color: #fff;
  z-index: 2000;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16),
    0 8px 16px rgba(27, 39, 51, 0.08);
  width: 462px;
}

// 通知窗口
.noti-list-content {
  position: relative;
  button {
    position: absolute;
    color: #f5a623;
    line-height: 21px;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 1050;
    display: block;
    background-color: #fff;
    padding: 8px 20px;
    font-size: 14px;
    border: none;
  }
  li {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    min-height: 60px;
    padding: 13px 40px 13px 24px;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 13px;
    line-height: 1.5;
    &:hover {
      background-color: #f6f6f7;
      .noti-position-time {
        opacity: 1;
      }
    }
    * {
      pointer-events: none;
    }
  }
  .noti-unread {
    background-color: #e7f1fe;
    &::before {
      content: "";
      position: absolute;
      left: 10px;
      top: 10px;
      background-color: #fd3a57;
      border-radius: 8px;
      width: 8px;
      height: 8px;
    }
  }
  .noti-con-info {
    position: relative;
    width: calc(100% - 60px);
  }
  .noti-position-time {
    position: absolute;
    top: 0;
    right: -30px;
    z-index: 1;
    opacity: 0;
    color: #8e959a;
    box-shadow: -20px 0 30px 10px #f6f6f7;
    background-color: #f6f6f7;
  }
  .noti-user-photo {
    flex: none;
    width: 32px;
    height: 32px;
    margin-right: 15px;
    border-radius: 100%;
    overflow: hidden;
  }
  .noti-scroll-container {
    min-height: 40vh;
    max-height: 60vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 11px;
    }
    &::-webkit-scrollbar-thumb {
      border: 2px solid #fff;
      background: #eaeaea;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      width: 9px;
      background: #fff;
      margin: 5px 0;
    }
  }
  .noti-scroll-time {
    position: sticky;
    line-height: 1.5;
    padding: 9px 18px;
    top: 0;
    z-index: 100;
    background-color: #fff;
    color: #8e959a;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .project-comp-ui {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    margin-top: 10px;
    padding-left: 14px;
    padding-right: 14px;
    box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16);
    color: #373839;
    .iconfont {
      color: #f5a623;
      font-size: 20px;
      margin-right: 10px;
    }
  }
}

// 头像修改
#userPhotoChangeDialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  #userPhotoChangeContainer {
    position: relative;
    z-index: 2;
    flex: none;
    width: 360px;
    height: 180px;
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    box-shadow: 0 0 0 10px #fff;
    border-radius: 6px;
    text-align: center;
    color: #606266;
    font-size: 14px;
    transition: border-color 0.3s;
    &:hover {
      border-color: #409eff;
    }
    &.drop-active {
      border-color: #409eff;
    }
    .el-icon-upload {
      font-size: 67px;
      color: #c0c4cc;
      margin: 40px 0 16px;
      line-height: 50px;
    }
    .el-upload__warn {
      font-size: 12px;
      color: #999;
      margin-top: 30px;
    }
    em {
      color: #409eff;
      font-style: normal;
    }
    .el-icon-upload,
    .el-upload__text,
    .el-upload__warn {
      pointer-events: none;
    }
  }
  #userPhotoChangeMaskClose {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  #userPhotoChangeInput {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.xpp-allowance-container {
  width: 525px;
  padding-top: 11px;
  padding-bottom: 31px;
  margin: 0 auto;
  .xpp-allowance-lable {
    height: 20px;
    line-height: 20px;
    span {
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
      font-weight: 600;
    }
    a {
      display: inline-block;
      border-radius: 2px;
      border: 1px solid rgba(255, 124, 124, 1);
      height: 20px;
      box-sizing: border-box;
      padding: 3px 5px;
      text-align: center;
      font-weight: 400;
      line-height: 12px;
      color: rgba(255, 124, 124, 1);
      font-size: 12px;
      margin-left: 15px;
    }
  }
  .xpp-progress-box {
    margin-top: 14px;
    .xpp-progress {
      height: 10px;
      background: rgba(237, 237, 237, 1);
      border-radius: 7px;
      overflow: hidden;
      margin-bottom: 13px;
      div {
        translate: width 0.3s;
        height: 10px;
        background: rgba(126, 211, 32, 1);
        border-radius: 7px;
      }
    }
  }
  .xpp-allowance-info {
    font-size: 12px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 17px;
  }
}
.xpp-dialog-close {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  text-align: center;
  line-height: 20px;
  color: rgba(218, 218, 218, 1);
}
#xppAllowanceCreditLow {
  position: fixed;
  z-index: $view-mask-modal;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  .xpp-allowance-contianer {
    position: relative;
    width: 503px;
    height: 175px;
    border-radius: 6px;
    padding-top: 133px;
    background: #fff url(/img/balance_icon.png) no-repeat center 41px;
    background-size: 128px 76px;
  }
  h3 {
    text-align: center;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
    font-size: 22px;
    line-height: 30px;
  }
  p {
    text-align: center;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    line-height: 17px;
    margin-top: 14px;
    margin-bottom: 39px;
  }
  a {
    display: block;
    text-align: center;
    width: 168px;
    height: 42px;
    line-height: 42px;
    background: rgba(245, 142, 52, 1);
    border-radius: 6px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 2px;
  }
}
.xpp-share-mode-title {
  color: #373839;
  font-size: 16px;
  font-weight: 600;
  height: 26px;
  line-height: 26px;
  margin-bottom: 28px;
}

.xpp-share-mode-container {
  &::before {
    content: attr(data-label);
    display: block;
    line-height: 17px;
    font-size: 14px;
    color: #373839;
    margin-bottom: 15px;
  }
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px #e0e0e0 solid;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .xpp-share-form-con {
    flex: auto;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #d7d7d7;
    display: flex;
    align-items: stretch;
  }
  .el-button {
    margin-left: 10px;
  }
  .el-select {
    width: 110px;
    text-align: center;
    border: none;
  }
  .xpp-share-input {
    flex: auto;
    height: 24px;
    line-height: 24px;
    padding: 8px 14px;
  }
}
.xpp-share-dialog {
  h2 {
    font-size: 16px;
    color: #373839;
    line-height: 24px;
  }
}
.xpp-sharemode-btn {
  color: #cf8d1f;
  font-size: 14px;
  line-height: 27px;
  cursor: pointer;
}
.xpp-share-usersearch {
  flex: auto;
}
.xpp-email-bind {
  background-color: #f5a623;
  color: #fff;
  border: 1px solid transparent;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  padding-left: 14px;
  padding-right: 14px;
}
.join-team-title {
  color: #f5a623;
  text-align: center;
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
}
.join-team-container {
  h3 {
    margin-bottom: 20px;
    color: #373839;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.6;
    text-align: center;
  }
  .xpp-user-photo {
    width: 100px;
    height: 100px;
    margin: 0 auto 15px;
  }
}
.join-team-info {
  text-align: center;
  font-size: 14px;
  b,
  i {
    font-style: initial;
  }
  b {
    color: #373839;
  }
  i {
    color: #f5a623;
  }
  span {
    color: #9e9166;
  }
}
.join-team-option {
  margin-top: 50px;
  padding: 0 10px;
  .el-button {
    width: 165px;
    &:hover {
      color: #f5a623;
      border-color: #f5a623;
      background-color: #f5a62314;
    }
  }
}
.join-team-remarks {
  color: #757575;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  span {
    color: #f5a623;
  }
}
</style>
<style lang="scss">
.xpp-share-select {
  min-width: 110px;
  background-color: #f3f3f3;
  .el-input {
    height: 100%;
  }
  .el-input__inner {
    height: 100%;
    background-color: #f3f3f3;
    text-align: center;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
}
.xpp-share-select-popper {
  text-align: center;
}
.xpp-share-dialog {
  .el-dialog__body {
    padding-top: 0;
  }
}
.join-team-dialoh {
  .el-dialog__body {
    padding-top: 0;
  }
}

.user-info-setting-body {
  background: transparent;
  border: none;
  .el-tabs__header {
    background-color: transparent;
    border-bottom: none;
    margin-bottom: 11px;
    .el-tabs__item {
      border-right-color: #e7e7e7;
      &:hover,
      &.is-active {
        color: #f5a623;
      }
    }
  }
  .el-tabs__nav {
    background-color: #fff;
    height: 62px;
    line-height: 62px;
  }
  .el-tabs__item {
    height: 29px;
    line-height: 29px;
  }
  .el-tabs__content {
    background-color: #fff;
  }
}
</style>
