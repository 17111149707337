import { render, staticRenderFns } from "./setUp.vue?vue&type=template&id=f7109140&scoped=true&"
import script from "./setUp.vue?vue&type=script&lang=js&"
export * from "./setUp.vue?vue&type=script&lang=js&"
import style0 from "./setUp.vue?vue&type=style&index=0&id=f7109140&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7109140",
  null
  
)

export default component.exports